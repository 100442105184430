import { Box, Button, CircularProgress, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import {
    AutocompleteFormik,
    ChangeRandNumberFormik,
    CheckboxFormik,
    CheckboxMultipleFormik,
    CloseObservationFormik,
    CollectionFormik,
    ConfirmButtonFormik,
    ConfirmChildrenButtonFormik,
    DataGridFormik,
    DatePickerFormik,
    DateTimePickerFormik,
    DicomButtonFormik,
    ElementHistoryModal,
    PharmIndexFormik,
    ProvideFormik,
    RadioFormik,
    RandomizeTypeFormik,
    S3UploaderFormik, SelectFormik,
    SelectMultipleFormik,
    SliderFormik,
    TableFormik,
    TableGridFormik,
    TextFieldFormik,
    TimePickerFormik,
    UploadFileFormik,
} from 'components/Form';
import ColoredBox from 'components/ColoredBox';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import { flatten, map } from 'lodash';
import { useAppDispatch, useAppSelector } from 'commons/store/hooks';
import {
    fetchEcrf,
    fetchEcrfElements,
    fetchElementDataCollection,
    postElementData,
    putElementData,
    selectCollection,
    selectEcrf,
    updateElementData,
    updateElementStatus
} from 'features/ecrf/redux/ecrfSlice';
import Status from 'core/helpers/Status';
import { selectUserProfile } from 'core/redux/userSlice';
import React, { useEffect, useState } from 'react';
import ElementHistory from 'core/helpers/ElementHistory';
import { useSnackbar } from 'notistack';
import { useField } from 'formik';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import ClearValues from 'core/helpers/ClearValue';
import StarRateIcon from '@mui/icons-material/StarRate';
import {
    StyledEcrfElement,
    StyledEcrfElementButtons,
    StyledEcrfElementButtonsWrapper,
    StyledEcrfElementLabels
} from './Ecrf.styled'
import SaveIcon from '@mui/icons-material/Save';
import { useParams } from "react-router-dom";
import DateTime from "core/helpers/date/DateTime";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckIcon from '@mui/icons-material/Check';
import AdminTools from "./FormContent/AdminTools";
import { Divider } from "./FormContent/Divider";
import {EditOffOutlined, Refresh} from "@mui/icons-material";
import Element from "core/helpers/Element";
import { DATE_FORMAT, DATETIME_FORMAT, TIME_FORMAT, TZ } from "commons/constants/env";
import { roles } from 'commons/auth/roles';
import { fetchElementHistory } from "../../redux/elementSlice";
import pl from 'date-fns/locale/pl'
import { format } from "date-fns-tz";
import { useTranslation } from "react-i18next";

type FormFieldProps = {
    // TODO swagger model for element
    actions?: any;
    element: any;
    elementData: any | null;
    elementStatus: number | undefined;
    setIsOpenQueryPanel: (elementId: string) => void;
    isOpenQueryPanel: string | null;
    hasActionPanel: boolean;
    hasQueryPanel?: boolean;
    hasBoxContainer?: boolean;
    hasElementDataInstance: boolean;
    ecrfId?: string;
    statusElement?: boolean;
    isTable?: boolean;
    showSave?: boolean;
    isAllSaving?: any | undefined;
    setIsAllSaving?: (isAllSaving: any) => void | undefined;
    updateSection?: (sectionId: string, visits: any) => void;
    parent?: any;
    onClear?: (params: any) => void;
    autoSave?: any;
};

const FormField = ({
   actions = ['save', 'history', 'clear', 'nd', 'status', 'query', 'refresh', 'import'],
   element,
   elementData,
   elementStatus = 0,
   setIsOpenQueryPanel,
   isOpenQueryPanel,
   hasActionPanel = true,
   hasQueryPanel = true,
   hasBoxContainer = true,
   hasElementDataInstance,
   showSave = true,
   statusElement,
   isTable = false,
   ecrfId = '',
   isAllSaving,
   setIsAllSaving,
   onClear,
   ...props
}: FormFieldProps) => {
    const dispatch    = useAppDispatch();
    const ecrf        = useAppSelector(selectEcrf);
    const collection = useAppSelector(selectCollection);
    const userProfile = useAppSelector(selectUserProfile);
    const { t } = useTranslation();

    const {enqueueSnackbar} = useSnackbar();
    const [field, meta, form] = useField(element.id);
    const {id = ecrfId, section}       = useParams<{ id: string, section: string }>();

    const [openModalHistory, setOpenModalHistory] = useState(false);
    const handleOpenModalHistory = () => setOpenModalHistory(true);
    const handleCloseModalHistory = () => setOpenModalHistory(false);
    const [isLoading, setIsLoading] = useState(false);

    /** Status of element */
    const [status, setStatus] = useState(elementStatus);
    const [isStatusSelectOpen, setIsStatusSelectOpen] = useState(false);
    const [isStatusLoading, setIsStatusLoading] = useState(false);

    /**
     * Change current element status
     * @param status
     * @param dataId
     */
    const handleChangeStatus = async (status: any, dataId?: string) => {
        setIsStatusLoading(true);

        try {
            setStatus(status)

            if (dataId) {
                await dispatch(updateElementData({id: dataId, status: parseInt(status)}));

                switch (props?.parent?.type) {
                    case 'table':
                    case 'collection':
                    case 'data_grid':
                    case 'adverse_event':
                        collection?.id && await dispatch(fetchElementDataCollection(collection?.id))
                        break;
                    default:
                        await dispatch(fetchEcrfElements({id, sectionId: section}));
                        await dispatch(fetchEcrf(id));
                }
            } else {
                switch (props?.parent?.type) {
                    case 'table':
                    case 'collection':
                    case 'data_grid':
                    case 'adverse_event':
                        collection?.id && await dispatch(postElementData({
                            ecrf: `/api/ecrves/${id}`,
                            element: `/api/elements/${element.id}`,
                            entries: [`/api/element_data_collections/${collection?.id}`],
                            status: parseInt(status)
                        }));

                        collection?.id && await dispatch(fetchElementDataCollection(collection?.id))
                        break;
                    default:
                        await dispatch(updateElementStatus({
                            elementId: element.id, ecrfId: id, status: parseInt(status)
                        }));

                        await dispatch(fetchEcrfElements({id, sectionId: section}));
                        await dispatch(fetchEcrf(id));
                }
            }
        } catch (error: any) {
            enqueueSnackbar(error?.detail || error?.message, {variant: 'error'});
        }

        setIsStatusLoading(false);
    };

    /**
     * Save data of element
     */
    const [isDataSaving, setIsDataSaving] = useState(isAllSaving)
    const saveData = async (clear?: boolean) => {
        setIsDataSaving(true)

        try {
            if (clear) {
                await form.setValue('')
                await dispatch(updateElementData({id: elementData?.id, data: []}))
            } else {
                let value: any = flatten([field.value]);

                switch (element?.type) {
                    case 'date':
                    case 'datetime':
                    case 'time': {
                        value = [DateTime.formatValue(element?.type, value)];
                        break;
                    }
                }

                await dispatch(putElementData({data: value, elementId: element?.id, ecrfId}))
            }

            await dispatch(fetchEcrfElements({id, sectionId: section}));
            await dispatch(fetchEcrf(id));

            enqueueSnackbar(`${t('saved')} ${element?.title} ${new Date().toLocaleTimeString()}`, {
                variant: 'info'
            });

            if (isDate) setSaved(true)
        } catch (error: any) {
            enqueueSnackbar(element?.title + ' - ' + (error?.detail || error?.message), {
                variant: 'warning'
            });
        }

        setIsDataSaving(false)
    }

    /**
     * Fetch history data of selected element
     * @param dataId
     */
    const handleElementHistory = async (dataId: string) => {
        setIsLoading(true);

        try {
            const historyData = await dispatch(fetchElementHistory(dataId)).unwrap();
            if (historyData && historyData?.length > 0) {
                handleOpenModalHistory();
            } else {
                enqueueSnackbar(t('no-history-changes'), {variant: 'info'});
            }
        } catch (error: any) {
            enqueueSnackbar(t('no-data-history'), {variant: 'warning'});
        }

        setIsLoading(false);
    };

    /** Set status */
    const statusValue = Status.controller(
        userProfile?.roles,
        ecrf?.status,
        statusElement,
        element.mode
    );

    /** Get state status */
    const statusSelectValue = Status.disabled(userProfile?.roles);
    // const statuses = Status.statuses(userProfile?.roles, element.statuses);

    /** Handle reset value button */
    const isResetPossible = ClearValues.displayController(
        userProfile?.roles,
        element.type,
        elementData,
        elementData?.status,
        ecrf?.status
    );

    /** Check element part type */
    const isCollection = Element.isCollection(element.type);
    const isDate       = Element.isDate(element.type);
    // const isFunctional = Element.isFunctional(element.type);

    /** Element action access */
    const actionsAccess = Element.actionsAccess(actions, userProfile?.roles);

    /**
     * 'No data' controller.
     * If return true will display red border and text 'No data'
     */
    const displayNoDataInfo = () => {
        switch (element?.type) {
            case 'table':
            case 'collection':
            case 'data_grid':
            case 'adverse_event':
            case 'button':
            case 'randomization': {
                return false;
            }
        }

        // check variant option
        if (element?.options?.variant === 'randNumber') return false

        // return status for data content
        return !elementData?.data;
    }
    /**
     * Border style data state
     */
    const borderStyleDataState = () => {
        // query panel
        if (isOpenQueryPanel === element.id) return '5px solid #F44336';

        // required
        if (element?.required) return '3px solid #F44336';

        // no data
        if (displayNoDataInfo()) {
            return '1px solid #F44336';
        }

        // return default border style
        return '1px solid #F7FAFF';
    }

    /**
     * Display save button
     */
    const displaySaveButton = () => {
        switch (element.type) {
            case 'adverse_event':
            case 'button':
            case 'collection':
            case 'data_grid':
            case 'dicom_button':
            case 'drug_allocation':
            case 'file':
            case 'randomization':
            case 'table':
                return false;
            default: {
                return true;
            }
        }
    }

    /**
     * Checkbox multiple - save status controller
     */
    const checkboxStatus = () => {
        if (element.type === 'checkbox_multiple' || element.options.pharmindex === 'true') {
            const isArray = Array.isArray(field.value)
            return isArray && (field.value.length === elementData?.data.length) &&
                field.value.every((value: any, index: any) => value === elementData?.data[index]);
        }

        return false;
    }

    const numberValue = () => {
        return !!(element.type === 'number' && field.value && !field.value.toString());
    }

    const [saved, setSaved] = useState(false)

    useEffect(() => {
        if (isDate) {
            setSaved(false)
        }
    }, [field.value])

    const [isRefreshElement, setRefreshElement] = useState(false);

    const updateData = async () => {
        await dispatch(fetchEcrfElements({id, sectionId: section}));
    }

    /**
     * Reload data element
     */
    const reloadElementData = () => {
        setRefreshElement(true);
    }

    /**
     * Render element
     */
    const renderInput = () => {
        switch (element.type) {
            case 'autocomplete':
                return <AutocompleteFormik
                    name={element.id || ''}
                    label={element.label}
                    options={element.options}
                    status={statusValue}
                    dataId={elementData?.id}
                    fullWidth
                    placeholder={element.options?.placeholder}
                    helperText={element.options?.help}
                    required={element?.required}
                />
            case 'data_grid': {
                return (
                    <DataGridFormik
                        setIsOpenQueryPanel={setIsOpenQueryPanel}
                        isOpenQueryPanel={isOpenQueryPanel}
                        name={element.id || ''}
                        status={statusValue}
                        label={element.label}
                        options={element?.options}
                        element={element}
                        children={element.children}
                        dataId={elementData?.id || ''}
                    />
                );
            }
            case 'table':
            case 'adverse_event': {
                return (
                    <>
                        {(element.options?.modal === 'false' || element.options?.modal === false)
                            ?
                            <TableGridFormik
                                type={element.type}
                                label={element.label}
                                name={element.id || ''}
                                children={element?.children}
                                elementData={elementData}
                                ecrfId={id}
                                elementStatus={statusValue}
                                element={element}
                                isRefreshElement={isRefreshElement}
                                setRefreshElement={setRefreshElement}
                                status={statusValue}
                            />
                            :
                            <TableFormik
                                setIsOpenQueryPanel={setIsOpenQueryPanel}
                                isOpenQueryPanel={isOpenQueryPanel}
                                name={element.id || ''}
                                status={statusValue}
                                label={element.label}
                                element={element}
                                data={elementData}
                                children={element.children}
                            />
                        }
                    </>
                );
            }
            case 'number':
            case 'text': {
                switch (element.options.variant) {
                    case 'randNumber': {
                        return (
                            <ChangeRandNumberFormik
                                type={element.type}
                                name={element.id || ''}
                                fullWidth
                                data={elementData?.data}
                                status={statusValue}
                                label={element.label}
                                placeholder={element.options?.placeholder}
                                helperText={element.options?.help}
                                required={element?.required}
                            />
                        )
                    }
                    default: {
                        return (
                            <TextFieldFormik
                                type={element.type}
                                name={element.id || ''}
                                fullWidth
                                // data={elementData?.data}
                                options={element?.options}
                                status={statusValue}
                                label={element.label}
                                placeholder={element.options?.placeholder}
                                helperText={element.options?.help}
                                required={element?.required}
                            />
                        )
                    }
                }
            }
            case 'association':
            case 'choice':
            case 'select': {
                return (
                    <>
                        {element.options.pharmindex === "true"
                            ?
                            <PharmIndexFormik
                                name={element.id || ''}
                                status={statusValue}
                                label={element.label}
                                placeholder={element.options?.placeholder}
                                helperText={element.options?.help}
                                value={field.value}
                                data={elementData?.data}
                                setValue={form.setValue}
                                options={element.options}
                                required={element?.required}
                            />
                            :
                            <SelectFormik
                                name={element.id || ''}
                                fullWidth
                                status={statusValue}
                                label={element.title || element.label}
                                placeholder={element.options?.placeholder}
                                helperText={element.options?.help}
                                options={element.options.choices}
                                required={element?.required}
                            />
                        }
                    </>
                );
            }
            case 'textarea': {
                return (
                    <TextFieldFormik
                        name={element.id || ''}
                        fullWidth
                        status={statusValue}
                        label={element.label}
                        placeholder={element.options?.placeholder}
                        helperText={element.options?.help}
                        multiline={true}
                        rows={10}
                        required={element?.required}
                    />
                );
            }
            case 'date': {
                return (
                    <DatePickerFormik
                        name={element.id || ''}
                        label={element.label}
                        data={elementData?.data}
                        status={statusValue}
                        fullWidth
                        placeholder={element.options?.placeholder}
                        helperText={element.options?.help}
                        format={element.options.format || DATE_FORMAT}
                        required={element?.required}
                    />
                );
            }
            case 'datetime': {
                return (
                    <DateTimePickerFormik
                        name={element.id || ''}
                        label={element.label}
                        status={statusValue}
                        fullWidth
                        data={elementData?.data}
                        placeholder={element.options?.placeholder}
                        helperText={element.options?.help}
                        format={element.options?.format || DATETIME_FORMAT}
                        required={element?.required}
                    />
                );
            }
            case 'time': {
                return (
                    <TimePickerFormik
                        name={element.id || ''}
                        label={element.label}
                        status={statusValue}
                        fullWidth
                        placeholder={element.options?.placeholder}
                        helperText={element.options?.help}
                        format={element.options?.format || TIME_FORMAT}
                        required={element?.required}
                    />
                );
            }
            case 'range': {
                return (
                    <SliderFormik
                        name={element.id}
                        id={element.id}
                        status={statusValue}
                        min={element.options?.attr?.min || 0}
                        max={element.options?.attr?.max || 100}
                    />
                );
            }
            case 'checkbox': {
                return <CheckboxFormik
                    name={element.id}
                    label={element.label}
                    status={statusValue}
                    required={element?.required}
                />;
            }
            case 'checkbox_multiple': {
                return (
                    <CheckboxMultipleFormik
                        id={element.id}
                        options={element.options}
                        status={statusValue}
                        required={element?.required}
                    />
                )
            }
            case 'collection': {
                return (
                    <CollectionFormik
                        name={element.id}
                        id={element.id}
                        status={elementData?.status}
                        label={element.label}
                        children={element.children}
                        dataId={elementData?.id}
                        setIsOpenQueryPanel={setIsOpenQueryPanel}
                        isOpenQueryPanel={isOpenQueryPanel}
                    />
                );
            }
            case 'radio': {
                return map(element.options.choices, (value, option) => {
                    return (
                        <RadioFormik
                            key={String(value)}
                            name={element.id}
                            label={option}
                            value={String(value)}
                            status={statusValue}
                            required={element?.required}
                        />
                    );
                });
            }
            case 'select_multiple': {
                return (
                    <SelectMultipleFormik
                        name={element.id}
                        fullWidth
                        status={statusValue}
                        label={element.label}
                        placeholder={element.options?.placeholder}
                        helperText={element.options?.help}
                        options={map(element.options.choices, (value, key) => ({
                            key,
                            value,
                        }))}
                        required={element?.required}
                    />
                );
            }
            case 'dicom_button': {
                switch (element?.options?.variant) {
                    default: {
                        return (
                            <DicomButtonFormik
                                name={element.id || ''}
                                id={element.id || ''}
                                fullWidth
                                status={statusValue}
                                link={element.options?.link}
                                helperText={element.options?.help}
                                options={map(element.options.choices, (value, key) => ({
                                    key,
                                    value,
                                }))}
                                extra={ecrfId || ''}
                                data={elementData?.data}
                            />
                        );
                    }
                }
            }
            case 'file': {
                return (
                    <S3UploaderFormik
                        status={statusValue}
                        data={elementData}
                        update={updateData}
                        ecrfId={ecrfId}
                        element={element}
                        readonly={userProfile.roles.some(
                            (role: string) => roles.dataManager.includes(role) || roles.query.includes(role)
                        )}
                        required={element?.required}
                    />
                )
            }
            case 'randomization': {
                return (
                    <RandomizeTypeFormik
                        name={element.id || ''}
                        id={element.id || ''}
                        status={statusValue}
                        fullWidth
                        link={element.options?.link}
                        helperText={element.options?.help}
                        options={map(element.options.choices, (value, key) => ({
                            key,
                            value,
                        }))}
                        extra={ecrfId || ''}
                        data={elementData?.data}
                    />
                );
            }
            case 'button': {
                return (
                    <>
                        {(element.options?.modal && element?.children?.length > 0)
                            ?
                            <ConfirmChildrenButtonFormik
                                name={element.id || ''}
                                id={element.id || ''}
                                fullWidth
                                status={statusValue}
                                options={element.options}
                                ecrfId={ecrfId || ''}
                                elementData={elementData}
                                dataId={elementData?.id || ''}
                                data={elementData?.data}
                                element={element}
                                children={element?.children}
                            />
                            :
                            <ConfirmButtonFormik
                                name={element.id || ''}
                                id={element.id || ''}
                                fullWidth
                                status={statusValue}
                                options={element.options}
                                ecrfId={ecrfId || ''}
                                elementData={elementData}
                                data={elementData?.data}
                                element={element}
                            />
                        }
                    </>
                );
            }
            // @todo check this element and remove, if no needed
            case 'close_observation_period': {
                return (
                    <CloseObservationFormik
                        name={element.id || ''}
                        id={element.id || ''}
                        fullWidth
                        link={element.options?.link}
                        helperText={element.options?.help}
                        options={map(element.options.choices, (value, key) => ({
                            key,
                            value,
                        }))}
                        extra={ecrfId || ''}
                        data={elementData?.data}
                        status={statusValue}
                    />
                );
            }
            case 'divider': {
                return (
                    <Divider key={element?.id} label={element.label} />
                )
            }
            case 'drug_allocation': {
                return (
                    <ProvideFormik
                        update={updateData}
                        name={element.id || ''}
                        data={elementData?.data}
                        ecrfId={ecrfId || ''}
                        status={statusValue}
                        required={element?.required}
                    />
                )
            }
            default: {
                return <div>{element.type}</div>;
            }
        }
    };

    /**
     * Render content element
     */
    const renderContentElement = () => {
        return (
            <StyledEcrfElement table={isTable} collection={isCollection}>
                <Box>
                    <Typography style={{justifySelf: 'start'}} variant="body1">
                        {element.title}
                    </Typography>
                </Box>

                <Box>{renderInput()}</Box>

                {props?.autoSave && (
                    <Box fontSize={'.6rem'} color={'#4B5563'}>
                        {t('saving')}...
                    </Box>
                )}

                {/** BUTTONS SECTION */}
                {element?.options?.variant !== 'randNumber' && (
                    <StyledEcrfElementButtonsWrapper>
                        {/** Save section */}
                        <Box>
                            {displaySaveButton() && !isTable && showSave && actionsAccess.includes('save') && (
                                <Button
                                    onClick={() => saveData()}
                                    size="small"
                                    variant={'outlined'}
                                    color="primary"
                                    disabled={isDataSaving
                                        || isAllSaving
                                        || (field.value === elementData?.data[0])
                                        || (element.type === 'number' ? numberValue() : !field.value)
                                        || field?.value?.length === 0
                                        || checkboxStatus()
                                        || saved}
                                    startIcon={(isDataSaving || isAllSaving)
                                        ? <CircularProgress color={"inherit"} size={14}/>
                                        : <SaveIcon/>}
                                    style={{padding: '5px 30px'}}>
                                    {(isDataSaving || isAllSaving) ? t('saving') : t('save')}
                                </Button>
                            )}
                        </Box>

                        <StyledEcrfElementButtons>
                            {/** History button -> open modal with history */}
                            {hasActionPanel && !isTable && !isCollection && ![
                                    'dicom_button',
                                    'drug_allocation',
                                    'file',
                                    'randomization'
                                ].includes(element.type) && actionsAccess.includes('history') && (
                                <Box>
                                    <Tooltip title={t('change-history')} arrow>
                                        <span>
                                            <Button
                                                onClick={() => handleElementHistory(elementData?.id)}
                                                size="small"
                                                variant={'outlined'}
                                                color="primary"
                                                disabled={!ElementHistory.controller(
                                                    element.type, userProfile.roles, isTable, elementData
                                                )}
                                                startIcon={isLoading ? <CircularProgress size={12}/> : <HistoryIcon/>}
                                                style={{padding: '5px 30px', width: "100%"}}>
                                                {t('history')}
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Box>
                            )}

                            {/** CLEAR VALUE button -> changes data to blank */}
                            {hasActionPanel && !['randomization'].includes(element.type) && actionsAccess.includes('nd') && (
                                <Box>
                                    <Tooltip title={t('not-done')} arrow>
                                        <span>
                                            <Button
                                                size="small"
                                                color="primary"
                                                variant={'outlined'}
                                                disabled={isStatusLoading}
                                                // value={'6'}
                                                startIcon={isStatusLoading
                                                    ? <CircularProgress size={12} />
                                                    : <EditOffOutlined />}
                                                onClick={() => handleChangeStatus('6', elementData?.id)}
                                                style={{padding: '5px 30px', width: "100%"}}>
                                                {t(element.statuses[6])}
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Box>
                            )}

                            {/** NOT DONE button -> changes data to not done state */}
                            {hasActionPanel && !isTable && !isCollection && ![
                                    'dicom_button',
                                    'drug_allocation',
                                    'file',
                                    'randomization'
                                ].includes(element.type) && actionsAccess.includes('clear') && (
                                <Box>
                                    <Tooltip title={t('clear-field')} arrow>
                                        <span>
                                            <Button
                                                size="small"
                                                color="primary"
                                                variant={'outlined'}
                                                disabled={
                                                    isDataSaving
                                                    || isAllSaving
                                                    || field?.value?.length === 0
                                                    || !isResetPossible
                                                }
                                                onClick={() => {
                                                    onClear ? onClear(form) : saveData(true)}
                                                }
                                                startIcon={<DeleteSweepRoundedIcon />}
                                                style={{padding: '5px 30px', width: "100%"}}>
                                                {t('clear')}
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Box>
                            )}


                            {/** Change element status Select */}
                            {(hasActionPanel && hasQueryPanel) && (
                                <Box>
                                    <Tooltip title={t('change-status')} arrow>
                                        <span>
                                            <Button
                                                id={element?.id}
                                                size="small"
                                                variant={'outlined'}
                                                color="primary"
                                                disabled={statusSelectValue || isStatusLoading}
                                                style={{padding: '5px 30px', width: "100%"}}
                                                onClick={() => setIsStatusSelectOpen(true)}
                                                startIcon={isStatusLoading
                                                    ? <CircularProgress size={12} />
                                                    : <StarRateIcon />}
                                            >
                                                <Box whiteSpace="nowrap">
                                                    {t(element.statuses[status])}
                                                </Box>
                                            </Button>
                                        </span>
                                    </Tooltip>
                                    <Select
                                        value={status}
                                        open={isStatusSelectOpen}
                                        style={{display: 'none'}}
                                        onChange={(e) => handleChangeStatus(e.target.value, elementData?.id)}
                                        onClose={() => setIsStatusSelectOpen(false)}
                                        MenuProps={{anchorEl: document.getElementById(element?.id)}}
                                    >
                                        {element.statuses.map((label: any, value: any) => {
                                            if (!userProfile.roles.includes('ROLE_C_RESEARCHER') && label.toLowerCase() === 'signed') {
                                                return;
                                            }

                                            return (
                                                <MenuItem key={label + value} value={value}>
                                                    {label}
                                                </MenuItem>
                                            )
                                        }
                                        )}
                                    </Select>
                                </Box>
                            )}

                            {/** Add Query button -> open QueryPanel */}
                            {(hasActionPanel && hasQueryPanel) && actionsAccess.includes('query') && (
                                <Box>
                                    <Tooltip
                                        title={elementData?.comment ?
                                            `${t('update')} ${DateTime.toIso(
                                                elementData?.comment?.updatedAt, DATETIME_FORMAT
                                            )}`
                                            : t('submit-query')}
                                        arrow>
                                        <span>
                                            <Button
                                                variant={'outlined'}
                                                size="small"
                                                color={'primary'}
                                                style={{padding: '5px 30px', width: "100%"}}
                                                startIcon={elementData?.status === 1
                                                    ? <CheckIcon/>
                                                    : elementData?.comment ? <ErrorOutlineOutlinedIcon/> : <AddIcon/>}
                                                onClick={() => element.id && setIsOpenQueryPanel(element.id)}
                                                disabled={
                                                    !userProfile.roles.some(
                                                        (role: string) => roles.query.includes(role) ||
                                                        roles.admin.includes(role)
                                                    ) && !elementData?.comment
                                                }>
                                                {t('query')}
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Box>
                            )}

                            {/** Import data */}
                            {(element.options?.import === 'true' || element.options?.import === true)
                                && (isTable || ['adverse_event', 'data_grid', 'table'].includes(element.type))
                                && actionsAccess.includes('import') && (
                                <Box>
                                    <UploadFileFormik
                                        status={statusValue}
                                        data={elementData}
                                        ecrfId={ecrfId}
                                        element={element}
                                        reload={reloadElementData.bind(this)}
                                    />
                                </Box>
                            )}

                            {/** Refresh data */}
                            {(element.options?.refresh === 'true' || element.options?.refresh === true)
                                && (isTable || element.type === 'table') && actionsAccess.includes('refresh') && (
                                <Box>
                                    <Tooltip title={t('refresh-field-data')} arrow>
                                        <span>
                                            <Button
                                                variant={'outlined'}
                                                size="small"
                                                color={'primary'}
                                                style={{padding: '5px 30px', width: "100%"}}
                                                startIcon={<Refresh/>}
                                                onClick={reloadElementData}
                                            >
                                                {t('refresh')}
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Box>
                            )}
                        </StyledEcrfElementButtons>
                    </StyledEcrfElementButtonsWrapper>
                )}

                {/** Modal with element history: possible to open when changes count > 1 */}
                <ElementHistoryModal openModal={openModalHistory} closeModal={handleCloseModalHistory}/>
            </StyledEcrfElement>
        );
    };

    if (hasBoxContainer === true) {
        return (
            <ColoredBox
                p={2}
                mb={2}
                border={borderStyleDataState()}
                style={{color: '#060A32'}}>
                {renderContentElement()}

                {!['adverse_event', 'collection', 'data_grid', 'table'].includes(element.type) && (
                    <>
                    {/* <--- Display 'No data' label ---> */}
                    {displayNoDataInfo() && (
                        <Box display={'flex'} mt={1}>
                            <Tooltip title={t('no-data-enter-na')} arrow>
                                <Box fontSize={'.7rem'} bgcolor={'#FEE2E2'} color={'#DC2626'}
                                     borderRadius={'522px'} p={'4px 21px'}>
                                    {t('no-data')}
                                </Box>
                            </Tooltip>
                        </Box>
                    )}
                    </>
                )}

                {/* <--- Display query and save label ---> */}
                {elementData?.updatedAt && (
                    <StyledEcrfElementLabels>
                        <Box color={elementData?.status === 1 ? '#16A34A' : '#0284C7'}
                             bgcolor={elementData?.status === 1 ? '#DCFCE7' : '#CFFAFE'} borderRadius={'522px'}
                             p={'4px 21px'}>
                            {elementData?.status === 1 ? t('verified') : t('saved')}
                        </Box>

                        {elementData?.comment && elementData?.status !== 1 && (
                            <Box fontSize={'.7rem'} color={'#CA8A04'} bgcolor={'#FEFCE8'} borderRadius={'522px'}
                                 p={'4px 21px'} maxWidth={'400px'} whiteSpace={'nowrap'} overflow={'hidden'}
                                 textOverflow={'ellipsis'}>
                                {t('query')} {DateTime.toIso(elementData?.comment?.updatedAt, DATETIME_FORMAT)}: {elementData?.comment?.content}
                            </Box>
                        )}
                    </StyledEcrfElementLabels>
                )}

                {/* Display admin tools - debugger, element info, change some fields */}
                {userProfile.roles.some((role: string) => ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN'].includes(role)) && (
                    <AdminTools element={element} id={ecrfId} data={elementData}/>
                )}
            </ColoredBox>
        );
    } else {
        return <Box mt={3}>{renderContentElement()}</Box>;
    }
};

export default FormField;
